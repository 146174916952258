<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Inventory</b> yang ada di {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/inventory/add')"
        >Tambah</b-button
      >
      <!-- <b-button
        squared
        variant="outline-primary"
        class="mx-1"
        @click="$router.push('/inventory/import')"
      >
        <i class="fas fa-file-excel pr-1"></i>
        Unggah Excel</b-button
      > -->
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body v-if="dataLoaded">
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.inventory_type_id"
                    :multiple="false"
                    :options="inventory_types"
                    @input="filterByInventoryType"
                  />
                </b-input-group>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.inventory_category_id"
                    :multiple="false"
                    :options="inventory_categories"
                    @input="filterByInventoryCategory"
                  />
                </b-input-group>
              </div>
              <div class="col-md-5">
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Saring Nama"
                  ></b-form-input>
                  <template #append>
                    <b-button squared @click="filterName" variant="success"
                      >Cari</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
              
            </div>
            <div class="row mt-2">
              <div class="col-md-2">
                <b-button variant="primary" @click="getExcel">
                  <i class="fas fa-file-excel pr-1"></i>
                  Export
                </b-button>
              </div>
            </div>

            <div class="overflow-auto">
              <b-table striped hover :items="items" :fields="fields">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '15%' : '' }"
                  />
                </template>
                <template #cell(photo)="data">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      image-container
                    "
                  >
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo" />
                    </div>
                  </div>
                </template>
                <template #cell(inventory_status)="data">
                  <b-badge
                    v-if="data.item.inventory_status_id == 1"
                    class="btn-green text-white"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 2"
                    class="text-white"
                    variant="danger"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 3"
                    class="text-white"
                    variant="info"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 4"
                    class="text-white"
                    variant="success"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 5"
                    class="text-white"
                    variant="warning"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/inventory/detail/' + data.item.id,
                      })
                    "
                  >
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/inventory/edit/' + data.item.id,
                      })
                    "
                  >
                    <i class="fas fa-edit px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    v-if="data.item.is_manipulate == 0"
                    @click="deleteData(data.item.id)"
                  >
                    <i class="fas fa-trash px-0"></i>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import XLSX from "xlsx";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        inventory_category_id: "",
        inventory_type_id: "",
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "inventory_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "inventory_remaining_stock",
          label: "Stok",
          sortable: true,
        },
        {
          key: "inventory_type_name",
          label: "Tipe",
          sortable: true,
        },
        {
          key: "inventory_status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
      dataLoaded: false,
      inventory_categories: [],
      inventory_types: [],

      // excel
      excel: [],
      // other
      setting: {},
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination();
    },
    async getSetting() {
      this.setting = await module.get(`settings/${1}`)
    },
    async pagination() {
      let filterParams = `&name=${this.filter.name}&inventory_category_id=${this.filter.inventory_category_id}&inventory_type_id=${this.filter.inventory_type_id}`;
      let response = await module.pagination(
        "inventories",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.dataLoaded = true;
    },

    async getExcel() {
      this.excel = [];

      SwalLoading.fire();

      let route = "inventory/no-pagination";

      let response = await module.get(route);
      this.excel = response;

      if (response) {
        Swal.close();
      }
      
      if (this.excel != []) {
        const data = XLSX.utils.json_to_sheet(this.excel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Daftar Inventory.xlsx`);
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("inventories/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async getInventoryCategoryOption() {
      let response = await module.setTreeSelect("inventory-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.inventory_categories = response.data;
        this.inventory_categories.unshift({
          label: "Saring Berdasar Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getInventoryTypeOption() {
      let response = await module.setTreeSelect("inventory-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.inventory_types = response.data;
        this.inventory_types.unshift({
          label: "Pilih Tipe Inventori",
          id: "",
          isDisabled: true,
        });
      }
    },

    async filterByInventoryCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.inventory_category_id = "";
      }
      this.pagination();
    },
    async filterByInventoryType(evt) {
      if (typeof evt == "undefined") {
        this.filter.inventory_type_id = "";
      }
      this.pagination();
    },
    
    filterName() {
      this.currentPage = 1;
      if (this.filter.name == "") {
        this.currentPage = this.lastCurrentPage;
      }
      this.pagination();
    },

    resetFilter() {
      this.filter.inventory_category_id = "";
      this.filter.inventory_type_id = "";
      this.filter.name = "";
      this.pagination();
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9003") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inventory" }]);
    // Get Data
    this.pagination();
    this.getSetting()
    this.setActiveMenu();
    this.getInventoryCategoryOption();
    this.getInventoryTypeOption();
  },
};
</script>
<style scoped>
.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>